import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleModal } from '../../../reducers/modalReducer';

// Not sure section constant
const NOT_SURE_ARRAY = [
    {
        icon: require('../../../../images/landingpage/loan.png'),
        spanText: 'Investment ready',
        btnKey: 1
    },
    {
        icon: require('../../../../images/landingpage/profit.png'),
        spanText: 'Growth ready',
        btnKey: 2
    },
    {
        icon: require('../../../../images/landingpage/open-door.png'),
        spanText: 'Exit ready',
        btnKey: 3
    }
];


const NotSureSection = ({ refs, isAnimationVisible }) => {

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const modal = useSelector(state => state.modal);

    const [test, setTest] = useState();
    const [isBtnHover, setIsBtnHover] = useState('');
    const [showAnimation, setShowAnimation] = useState(0)
    const [refreshAnimation, setRefreshAnimation] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            if (showAnimation == 2) {
                setShowAnimation(0)
                setRefreshAnimation(pre => !pre)
            }
            else {
                setShowAnimation(showAnimation + 1)
                setRefreshAnimation(pre => !pre)
            }
        }, 2200);
    }, [refreshAnimation]);

    useEffect(() => {
        let test = window.location.href.split('#')[1];
        let array = NOT_SURE_ARRAY.map(element => (element?.['spanText']?.split(' '))[0]);
        if (test && !test.includes('fit-report')) {
            if (array.length > 0 && array.filter(item => item == test)) welcome2MinuteTest(test);
        }
    }, []);

    const handleLetsGo = () => {
        if (test) localStorage.setItem('testType', test.toLowerCase());
        dispatch(toggleModal({ open: 'addCompany' }));
    };

    const welcome2MinuteTest = async (test) => {
        dispatch(toggleModal({ open: 'welcome2MinuteTest' }));
        setTest(test);
    };

    return (
        <>
            <div className="pr bg0 not-sure-container">
                <div ref={refs?.current?.[8]} className={`tslp ${isAnimationVisible[8] ? 'op1 tty0' : 'op0 ttx20'}`}>
                    <p className="fw500 tac c15 fs2-125rem" style={{ lineHeight: '62.2px', }}>
                        Not Sure <span className="c23" >Yet?</span>
                    </p>
                    <div className="w100 df jcc">
                        <p className="fw400 tac c15 pt1rem fs1rem" style={{ lineHeight: '24px', width: '37%' }}>
                            Find out by taking our FREE 2-minute Diligentsia Test.
                            No email or credit card required.
                        </p>
                    </div>
                </div>
                <div className="df jcsb w100 mt6rem mb3rem" style={{ gap: '3%' }}>
                    {NOT_SURE_ARRAY.map((obj, index) => {
                        return (
                            <div ref={refs?.current?.[8]} className={`bsflp bg1 br30px pr tslp pt2rem pb2rem pr2rem pl2rem df aic fdc ${isAnimationVisible[8] && 'scaleAnimation'}`} key={`${obj?.spanText}-${index}`} style={{ width: '34%' }}>
                                <div className="pa df jcc aic p2rem bg3" style={{ top: '-49px', left: '50%', transform: 'translateX(-50%)', width: '25px', height: '25px', borderRadius: '50%' }}>
                                    <img src={obj?.icon} />
                                </div>
                                <h1 className="fw500 tac c15 fs1-5rem pr0-5rem pl0-5rem mt1rem df fdc" style={{ lineHeight: '39.81px' }}>
                                    <div>How <span className="fw700 fs1-5rem tac c15 fsi" style={{ lineHeight: '39.81px', }}>{obj?.spanText}</span></div>
                                    <div>are you?</div>
                                </h1>
                                <div className="df jcc mt1rem">
                                    <Link to={`#${(obj?.spanText?.split(' '))[0]}`} onClick={() => welcome2MinuteTest((obj?.spanText?.split(' '))[0])} onMouseEnter={() => setIsBtnHover(obj?.btnKey)} onMouseLeave={() => setIsBtnHover('')}
                                        className={`br35px pr cp ${isBtnHover === obj?.btnKey ? "c1" : "c4"} ${showAnimation == index && "arrowBtnAnimation"} df jcc aic`} style={{ transition: "background-color 0.3s ease", background: isBtnHover === obj?.btnKey ? "#FC7785" : 'transparent', border: '1px solid #FC7785', padding: '10px 26px' }}>
                                        2-Minute Test
                                        <svg className={``} width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: isBtnHover === obj?.btnKey ? '#ffff' : '#FC7785', height: '18px', width: '21px' }}>
                                            <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill={isBtnHover === obj?.btnKey ? '#ffff' : '#FC7785'} />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="df jcc mt5rem">
                    <p className="fw400 fs1rem w100 tac c15" style={{ lineHeight: '24px', fontSize: '0.96rem' }}>
                        *Answer a number of bespoke multiple choice questions and get a report indicating
                        how ready your company is, including risk scores. Chance to improve with
                        recommendations and AI-enhanced set of KPIs specific for your business.
                        To store your test at the end you have option to register for FREE.
                    </p>
                </div>
                <img src={require('../../../../images/landingpage/right-side-zak.png')} className="pa right-side-zak" style={{ top: '-115px', height: '30%', left: "-58px", rotate: '97deg' }} />
                <img src={require('../../../../images/landingpage/circle.png')} className="pa ar" style={{ right: "110px", bottom: "80px", width: '4%' }} />
                <img src={require('../../../../images/landingpage/side-blue-circle-cut.png')} className="pa side-blue-circle" style={{ width: '139px', bottom: '0px', right: '0px' }} />
            </div >

            {/* Welcome 2 Minute Test New Modal */}
            < Modal id="welcome2MinuteTestModal" open={modal['open'] == 'welcome2MinuteTest'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
                <div className={`mah90 oya bg20 shadow w100 pt3rem pl3rem pr3rem pb2rem bsbb df fdc m1rem br20px pr maw80`}>
                    <button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
                        <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                        </svg>
                    </button>
                    <h1 className="c15 fs2rem fw500 mb2rem">FREE “2-Minute” Test</h1>
                    <div className="df jcsb aic step-box-modal style-two">
                        <img className='w100' src={require('../../../../images/landingpage/two-minute-popup.png')} alt='' />
                    </div>
                    <div className='tac w100'>
                        <p className='c15'>To see how simple the process is, experience it first hand.<br />Take the test and see.</p>
                        <button className='p1rem pl5rem pr5rem shadow c1 bg4 bg3-hover br35px mt1rem mb1rem cp fw600 fs1rem pr zi0 br10px td250 registerButtonAnimation' onClick={() => handleLetsGo()}>Let’s Go!</button>
                    </div>
                </div>
            </Modal >
        </>
    );

}

export default NotSureSection;